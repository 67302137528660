function daysBetween(date1, date2) {
  // Create Date objects using UTC to avoid timezone changes
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())

  // Calculate the difference in milliseconds, then convert to days
  const msPerDay = 24 * 60 * 60 * 1000
  return Math.floor((utc2 - utc1) / msPerDay)
}
$(function () {
  var ie = (function () {
    var undef,
      v = 3,
      div = document.createElement('div'),
      all = div.getElementsByTagName('i')

    while (
      ((div.innerHTML = '<!--[if gt IE ' + ++v + ']><i></i><![endif]-->'),
      all[0])
    );

    return v > 4 ? v : undef
  })()
  if (ie) {
    ;(function ($) {
      $._get = $.get
      $._post = $.post
      $.get = function (url, data, callback, type) {
        // try XDR
        if (jQuery.browser.msie && window.XDomainRequest) {
          var params = ''
          for (var key in data) {
            params +=
              (params || url.indexOf('?') != -1 ? '&' : '?') +
              key +
              '=' +
              data[key]
          }
          // Use Microsoft XDR
          var xdr = new XDomainRequest()
          xdr.open('GET', url + params)
          xdr.onload = function () {
            callback(this.responseText, 'success')
          }
          xdr.send()
        } else {
          try {
            // Try using jQuery to get data
            $._get(url, data, callback, type)
          } catch (e) {
            alert(e.message)
          }
        }
      }
      $.post = function (url, data, callback, type) {
        // Try XDR
        if (jQuery.browser.msie && window.XDomainRequest) {
          var params = ''
          for (var key in data) {
            params += (params ? '&' : '') + key + '=' + data[key]
          }
          // Use XDR
          var xdr = new XDomainRequest()
          xdr.open('POST', url)
          xdr.send(params)
          xdr.onload = function () {
            callback(xdr.responseText, 'success')
          }
        } else {
          try {
            // Try using jQuery to POST
            $._post(url, data, callback, type)
          } catch (e) {
            alert(e.message)
          }
        }
      }
    })(jQuery)
  }

  document.getElementsByTagName('html')[0].className = 'jsOn'
  $(function () {
    domain = 'https://ostseeurlaub-im-strandpark.de/Reservations/api/'
    buchungen = []
    saisons = []
    services = []
    fields = [
      '.vorname',
      '.nachname',
      '.tel',
      '.email',
      '.ort',
      '.plz',
      '.strasse',
      '.kinder',
      '.erwachsene',
    ]
    fieldsWithAlerts = [
      '.vorname',
      '.nachname',
      '.tel',
      '.email',
      '.ort',
      '.plz',
      '.strasse',
      '.kinder',
      '.erwachsene',
      '.alerts-div',
    ]

    $('a[data-toggle="tab"]').on('show.bs.tab', function (e) {
      for (field in fieldsWithAlerts) {
        var cField = fieldsWithAlerts[field]

        var previousField = $(cField)
        var newField = $(cField + '-inactive')
        console.log(previousField, newField)
        newField.val(previousField.val())

        cField = cField.replace('.', '')
        previousField.removeClass(cField)
        previousField.addClass(cField + '-inactive')
        newField.removeClass(cField + '-inactive')
        newField.addClass(cField)
      }
      $('.selectpicker').selectpicker('render')
    })

    updateFieldState = function (ret) {
      for (field in fields) {
        cfield = fields[field]
        found = false

        for (i in ret) {
          for (ii in ret[i]) {
            if (cfield == ret[i][1][ii]) found = true
          }
        }
        if (found) {
          $(fields[field]).addClass('ui-state-error')
        } else {
          $(fields[field]).removeClass('ui-state-error')
        }
      }
    }

    validateEmail = function (email) {
      var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    }

    //Makes a Dateobject out of our Type of Datestrings
    getIDate = function (string) {
      arr = string.split('-')
      newDate = new Date(
        parseInt(arr[0], 10),
        parseInt(arr[1], 10) - 1,
        parseInt(arr[2], 10)
      )
      newDate.setHours(2)
      return newDate
    }

    showInfoWidget = function (text) {
      $('.alerts-div').html(text)
    }
    buildInfoWidget = function (arr) {
      var text =
        '<div class="panel panel-danger" style="background-color:transparent"><div class="panel-heading">Warnung</div><ul class="list-group" style="background-color:transparent">'
      for (i in arr) {
        text +=
          '<li class="list-group-item" style="background-color:transparent">' +
          arr[i][0] +
          '</li>'
      }
      text += '</ul></div>'
      showInfoWidget(text)
    }
    //Makes our type of Datestrings from a Dateobject
    getTimeString = function (data) {
      let month = data.getMonth() + 1
      let day = data.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      let dateString = data.getFullYear() + '-' + month + '-' + day

      return dateString
    }

    getSaisonForDay = function (date) {
      saison = ''
      date = new Date(new Date(date).setHours(2))
      for (x in saisons) {
        if (getIDate(saisons[x].anfang).getTime() <= date.getTime()) {
          saison = saisons[x]
        } else break
      }
      return saison
    }
    getServicePreisForDay = function (date, service) {
      val = 0
      for (i in service.preise) {
        if (getIDate(service.preise[i].anfang).getTime() <= date.getTime()) {
          val = service.preise[i].preis
        }
      }
      return parseInt(val)
    }

    getServicePreisInfoObjForDay = function (date, service) {
      val = null
      for (i in service.preise) {
        var ende = service.preise[i].ende
        if (
          getIDate(service.preise[i].anfang).getTime() <= date.getTime() &&
          (!ende || getIDate(ende).getTime() >= date.getTime())
        ) {
          val = service.preise[i]
        }
      }
      return val
    }

    renderDay = function (data, current) {
      //
      abreiseB = 0
      if ($(this).datepick('getDate').length == 0) {
        abreiseB = 0
      } else if (
        $(this).datepick('getDate')[0].getTime() !=
        $(this).datepick('getDate')[1].getTime()
      ) {
        abreiseB = 0
      } else abreiseB = 1

      nothingSelected = $(this).datepick('getDate').length == 0
      onlyFirstDateSelected = 0
      if ($(this).datepick('getDate').length == 0) {
        onlyFirstDateSelected = 0
      } else if ($(this).datepick('getDate').length == 1) {
        onlyFirstDateSelected = 1
      } else if (
        $(this).datepick('getDate')[0].getTime() ==
        $(this).datepick('getDate')[1].getTime()
      ) {
        onlyFirstDateSelected = 1
      }

      date = getTimeString(data)
      bookable = true
      cssclass = 'cellFrei'
      if (buchungen[date]) {
        if (buchungen[date]['belegt']) {
          bookable = false
          cssclass = 'cellBelegt'
        }
        if (buchungen[date]['abreise']) {
          bookable = !Boolean(abreiseB)
          cssclass = 'cellAbreise'
        }
        if (buchungen[date]['anreise']) {
          bookable = Boolean(abreiseB)
          cssclass = 'cellAnreise'
        }
        if (buchungen[date]['anreise'] && buchungen[date]['abreise']) {
          bookable = false
          cssclass = 'cellBelegt'
        }
      }

      reise = $(this).datepick('getDate')[abreiseB]

      if (reise) {
        anreise = new Date(getTimeString($(this).datepick('getDate')[0]))
        abreise = anreise
        if ($(this).datepick('getDate').length == 2)
          abreise = new Date(getTimeString($(this).datepick('getDate')[1]))
        currentDate = new Date(getTimeString(data))

        if (abreise.getTime() == currentDate.getTime()) {
          cssclass += ' ui-state-active '
        }
        if (
          anreise.getTime() <= currentDate.getTime() &&
          currentDate.getTime() <= abreise.getTime()
        ) {
          cssclass += ' ui-state-active '

          //ifdata anreise
          if (currentDate.getTime() == anreise.getTime()) {
            cssclass = ' cellAnreiseActive '
          }
          if (currentDate.getTime() == abreise.getTime()) {
            if (abreiseB) {
              cssclass = ' cellAnreiseActive '
            } else {
              cssclass = ' cellAbreiseActive '
            }
          }
          if (buchungen[date]) {
            if (buchungen[date]['anreise']) {
              cssclass = 'cellAnreise cellAbreiseActive'
            }
            if (buchungen[date]['abreise']) {
              cssclass = 'cellAbreise cellAnreiseActive'
            }
            if (buchungen[date]['belegt']) {
              cssclass = 'cellBelegt '
            }
            if (buchungen[date]['anreise'] && buchungen[date]['abreise']) {
              bookable = false
              cssclass = 'cellBelegt'
            }
          }
        }
      }

      day = data.getDay()
      csaison = getSaisonForDay(data)

      if (csaison.is_chaos) {
        if (onlyFirstDateSelected) {
          if (
            csaison.min_days &&
            onlyFirstDateSelected &&
            daysBetween(
              new Date(getTimeString($(this).datepick('getDate')[0])),
              data
            ) < csaison.min_days
          ) {
            bookable = false
            cssclass += ' ui-state-disabled'
          }
          if (buchungen[date] && buchungen[date]['belegt']) {
          } else {
            if (day == 1 && !csaison.is_departure_monday) {
              bookable = false
              cssclass += ' ui-state-disabled'
            }
            if (day == 2 && !csaison.is_departure_tuesday) {
              bookable = false
              cssclass += ' ui-state-disabled'
            }
            if (day == 3 && !csaison.is_departure_wednesday) {
              bookable = false
              cssclass += ' ui-state-disabled'
            }
            if (day == 4 && !csaison.is_departure_thursday) {
              bookable = false
              cssclass += ' ui-state-disabled'
            }
            if (day == 5 && !csaison.is_departure_friday) {
              bookable = false
              cssclass += ' ui-state-disabled'
            }
            if (day == 6 && !csaison.is_departure_saturday) {
              bookable = false
              cssclass += ' ui-state-disabled'
            }
            if (day == 0 && !csaison.is_departure_sunday) {
              bookable = false
              cssclass += ' ui-state-disabled'
            }
          }
        } else {
          if (buchungen[date] && buchungen[date]['belegt']) {
          } else {
            if (day == 1 && !csaison.is_arrival_monday) {
              bookable = false
              cssclass += ' ui-state-disabled'
            }
            if (day == 2 && !csaison.is_arrival_tuesday) {
              bookable = false
              cssclass += ' ui-state-disabled'
            }
            if (day == 3 && !csaison.is_arrival_wednesday) {
              bookable = false
              cssclass += ' ui-state-disabled'
            }
            if (day == 4 && !csaison.is_arrival_thursday) {
              bookable = false
              cssclass += ' ui-state-disabled'
            }
            if (day == 5 && !csaison.is_arrival_friday) {
              bookable = false
              cssclass += ' ui-state-disabled'
            }
            if (day == 6 && !csaison.is_arrival_saturday) {
              bookable = false
              cssclass += ' ui-state-disabled'
            }
            if (day == 0 && !csaison.is_arrival_sunday) {
              bookable = false
              cssclass += ' ui-state-disabled'
            }
          }
        }
      } else {
        onlyAnreiseDay = csaison.anreiseOnly
        if (onlyAnreiseDay == null) onlyAnreiseDay = day
        if (onlyAnreiseDay != day) {
          bookable = false
          if (buchungen[date] && buchungen[date]['belegt']) {
          }
          if (
            csaison.min_days &&
            reise &&
            daysBetween(
              new Date(getTimeString($(this).datepick('getDate')[0])),
              data
            ) >= csaison.min_days
          ) {
            if (cssclass.indexOf('cellBelegt') == -1) {
              bookable = true
            }
          } else {
            cssclass += ' ui-state-disabled'
          }
        }
      }
      cssclass += ' ui-state-default'

      if (data < new Date()) {
        cssclass = ' cellBelegt '
        bookable = false
      }
      return !current
        ? {}
        : {
            selectable: bookable,
            dateClass: cssclass,
          }
    }

    Preis = 0
    calcPreis = function () {
      pr = 0
      anreise = new Date($('#anreise').datepick('getDate')[0].getTime())
      abreise = new Date($('#anreise').datepick('getDate')[1].getTime())
      //normale tage geld
      saisonC = getSaisonForDay(anreise)
      saisonPr = 0
      while (anreise.getTime() < abreise.getTime()) {
        if (saisonC != getSaisonForDay(anreise)) {
          if (saisonC.doRabatt) {
            if (saisonPr >= saisonC.preis * saisonC.rabattTageCount) {
              multiple = Math.floor(
                saisonPr / (saisonC.preis * saisonC.rabattTageCount)
              )
              less = multiple * saisonC.rabattTageCount * saisonC.rabattPreis
              more = multiple * saisonC.rabattTageCount * saisonC.preis
              diff = more - less
              saisonPr -= diff
            }
          }
          saisonC = getSaisonForDay(anreise)
          pr += saisonPr
          saisonPr = 0
        }
        anreise = new Date(anreise.setDate(anreise.getDate() + 1))
        saisonPr += parseInt(saisonC.preis)
      }
      if (saisonC.doRabatt) {
        if (saisonPr >= saisonC.preis * saisonC.rabattTageCount) {
          multiple = Math.floor(
            saisonPr / (saisonC.preis * saisonC.rabattTageCount)
          )
          less = multiple * saisonC.rabattTageCount * saisonC.rabattPreis
          more = multiple * saisonC.rabattTageCount * saisonC.preis
          diff = more - less
          saisonPr -= diff
        }
      }
      pr += saisonPr

      //services
      $('input[type=checkbox]:checked').each(function (index, a) {
        var info = getServicePreisInfoObjForDay(
          new Date($('#anreise').datepick('getDate')[0].getTime()),
          services[a.value]
        )
        if (!info) {
          return
        }
        if (info.berechnung === 1) {
          pr += parseInt(info.preis)
        } else {
          var anreisef = new Date(
            $('#anreise').datepick('getDate')[0].getTime()
          )
          var abreisef = new Date(
            $('#anreise').datepick('getDate')[1].getTime()
          )
          var tage = Math.floor((abreisef - anreisef) / 86400000)
          if (tage < info.stepdays) {
            tage = info.stepdays
          }
          var preis =
            (tage - info.stepdays) * parseInt(info.step) + parseInt(info.preis)
          if (parseInt(info.max_preis) && preis > parseInt(info.max_preis)) {
            pr += parseInt(info.max_preis)
          } else {
            pr += preis
          }
        }
      })
      //services mit anzahl
      $('#servicesDiv select').each(function (index, a) {
        pr +=
          getServicePreisForDay(
            new Date($('#anreise').datepick('getDate')[0].getTime()),
            services[a.name]
          ) * parseInt(a.value)
      })

      Preis = pr
      $('#buchPreis').text(Preis + '€')
    }

    validate = function () {
      anreise = $('#anreise').datepick('getDate')[0]
      abreise = $('#anreise').datepick('getDate')[1]
      $('.alerts-div').html('')

      // kinder / eltern sind zahlen
      if (isNaN(parseInt($('.erwachsene').val()))) {
        $('.erwachsene').val(0)
      } else $('.erwachsene').val(parseInt($('.erwachsene').val()))

      if (isNaN(parseInt($('.kinder').val()))) {
        $('.kinder').val(0)
      } else $('.kinder').val(parseInt($('.kinder').val()))

      //anreise und abreise gesetzt
      if (anreise == null || abreise == null) {
        return [['Wählen Sie ein An- und ein Abreisedatum!', ['#anreise']]]
      }

      anreise = new Date(anreise.getTime())
      abreise = new Date(abreise.getTime())

      //anreise < abreise frage
      if (anreise.getTime() > abreise.getTime()) {
        return [['Abreise nicht vor der Anreise möglich!', ['#anreise']]]
      }

      //keine buchung dazwischen
      while (anreise.getTime() < abreise.getTime()) {
        buchung = buchungen[getTimeString(anreise)]
        if (buchung) {
          if (buchung['belegt']) {
            return [
              [
                'Zwischen An- und Abreise liegt bereits eine Buchung vor!',
                ['#anreise'],
              ],
            ]
          }
        }
        anreise = new Date(anreise.setDate(anreise.getDate() + 1))
      }

      //validate anreise / abreise day nach saisonverboten
      anreise = new Date($('#anreise').datepick('getDate')[0].getTime())
      anday = anreise.getDay()
      abday = abreise.getDay()
      onlyAnreiseDay = getSaisonForDay(anreise).anreiseOnly
      onlyAbreiseDay = getSaisonForDay(abreise).abreiseOnly
      if (onlyAnreiseDay == null) onlyAnreiseDay = anday
      if (onlyAbreiseDay == null) onlyAbreiseDay = abday

      if (onlyAnreiseDay != anday) {
        return [['Gesperrter Anreisewochentag!', ['#anreise']]]
      }

      const minDays = getSaisonForDay(anreise).min_days
      if (onlyAbreiseDay != abday && !minDays) {
        return [['Gesperrter Abreisewochentag!', ['#anreise']]]
      }
      if (daysBetween(anreise, abreise) < minDays) {
        return [
          [
            'Mindestaufenthalt ' + minDays + ' Tage nicht erreicht',
            ['#anreise'],
          ],
        ]
      }

      //alles gut berechne preis
      calcPreis()
      return []
    }
    validateBuchung = function (ret) {
      if (!(ret.length > 0)) {
        if (
          $('#anreise').datepick('getDate')[0].getTime() ==
          $('#anreise').datepick('getDate')[1].getTime()
        ) {
          ret.push([
            'An- und Abreise nicht am selben Tag möglich!',
            ['#anreise'],
          ])
        }
      }
      if ($('.vorname').val() == '') {
        ret.push(['Geben Sie ihren Vornamen an!', ['.vorname']])
      }
      if ($('.nachname').val() == '') {
        ret.push(['Geben Sie ihren Nachnamen an!', ['.nachname']])
      }
      if ($('.email').val() == '') {
        ret.push(['Geben Sie ihre E-Mail Adresse an!', ['.email']])
      } else {
        if (!validateEmail($('.email').val())) {
          ret.push(['Geben Sie eine gueltige E-Mail Adresse an!', ['.email']])
        }
      }
      if ($('.tel').val() == '') {
        ret.push(['Geben Sie ihre Telefonnummer an!', ['.tel']])
      }
      if (parseInt($('.erwachsene').val()) + parseInt($('.kinder').val()) > 6) {
        ret.push([
          'Sie haben mehr als 6 Personen eingetragen',
          ['.kinder', '.erwachsene'],
        ])
      }
      if (parseInt($('.erwachsene').val()) > 4) {
        ret.push([
          'Sie haben mehr als 4 Erwachsene eingetragen',
          ['.erwachsene'],
        ])
      }
      if ($('.erwachsene').val() == '') {
        ret.push(['Geben Sie die Anzahl der Erwachsenen an!', ['.erwachsene']])
      }
      if (parseInt($('.erwachsene').val()) + parseInt($('.kinder').val()) < 1) {
        ret.push([
          'Geben Sie die Anzahl der Erwachsenen und Kinder ein',
          ['.erwachsene', '.kinder'],
        ])
      }
      if ($('.strasse').val() == '') {
        ret.push(['Geben Sie ihre Straße und Hausnummer an!', ['.strasse']])
      }
      if ($('.plz').val() == '') {
        ret.push(['Geben Sie ihre Postleitzahl an!', ['.plz']])
      }
      if ($('.ort').val() == '') {
        ret.push(['Geben Sie ihren Wohnort an!', ['.ort']])
      }
      //services
      $('input[type=checkbox]:checked').each(function (index, a) {
        if (
          !getServicePreisInfoObjForDay(
            new Date($('#anreise').datepick('getDate')[0].getTime()),
            services[a.value]
          )
        ) {
          ret.push([
            'Service ' +
              services[a.value].name +
              ' ist in der gewaehlten Saison nicht verfuegbar',
            ['#anreise'],
          ])
          return
        }
        if (
          getServicePreisForDay(
            new Date($('#anreise').datepick('getDate')[0].getTime()),
            services[a.value]
          ) == 0
        ) {
          ret.push([
            'Service ' +
              services[a.value].name +
              ' ist in der gewaehlten Saison nicht verfuegbar',
            ['#anreise'],
          ])
        }
      })
      //services mit anzahl
      $('#servicesDiv select').each(function (index, a) {
        if (
          getServicePreisForDay(
            new Date($('#anreise').datepick('getDate')[0].getTime()),
            services[a.name]
          ) == 0 &&
          parseInt(a.value) > 0
        ) {
          ret.push([
            'Service ' +
              services[a.name].name +
              ' ist in der gewaehlten Saison nicht verfuegbar',
            ['#anreise'],
          ])
        }
      })
      return ret
    }

    postSendBuchungTime = false
    sendBuchung = function () {
      ret = validate()
      ret = validateBuchung(ret)

      postSendBuchungTime = true
      //Wir haben Warnungen/Fehler
      updateFieldState(ret)
      if (ret.length > 0) {
        buildInfoWidget(ret)
        return
      }

      data = {}
      data['direkt'] = 1
      data['vorname'] = $('.vorname').val()
      data['nachname'] = $('.nachname').val()
      data['plz'] = $('.plz').val()
      data['strasse'] = $('.strasse').val()
      data['ort'] = $('.ort').val()
      data['email'] = $('.email').val()
      data['tel'] = $('.tel').val()
      data['erwachsene'] = $('.erwachsene').val()
      data['kinder'] = $('.kinder').val()
      data['zusatztext'] = ''
      data['preis'] = Preis
      data['anreise'] = getTimeString($('#anreise').datepick('getDate')[0])
      data['abreise'] = getTimeString($('#anreise').datepick('getDate')[1])

      data['services'] = ''
      $('input[type=checkbox]:checked').each(function (index, a) {
        data['services'] += a.name + ','
      })
      $('#servicesDiv select').each(function (index, a) {
        data['services'] += services[a.name].id + ','
      })
      data['servicesC'] = ''
      $('input[type=checkbox]:checked').each(function (index, a) {
        data['servicesC'] += 1 + ','
      })
      $('#servicesDiv select').each(function (index, a) {
        data['servicesC'] += a.value + ','
      })

      $.ajax({
        type: 'POST',
        url: domain + 'buchungen/',
        data: data,
        crossDomain: true,
        dataType: 'text',
        success: function (data) {
          alert(data)
        },
        error: function (data) {
          alert(
            data +
              'Irgendetwas ging schief! Sie koennen alternativ auch einfach eine E-Mail an buchung@ostseeurlaub-im-strandpark.de schicken oder anrufen.'
          )
        },
      })
    }
    sendAnfrage = function () {
      ret = validate()
      ret = validateBuchung(ret)

      postSendBuchungTime = true
      //Wir haben Warnungen/Fehler
      updateFieldState(ret)
      if (ret.length > 0) {
        buildInfoWidget(ret)
        return
      }

      data = {}
      data['direkt'] = 0
      data['vorname'] = $('.vorname').val()
      data['nachname'] = $('.nachname').val()
      data['plz'] = $('.plz').val()
      data['strasse'] = $('.strasse').val()
      data['ort'] = $('.ort').val()
      data['email'] = $('.email').val()
      data['tel'] = $('.tel').val()
      data['erwachsene'] = $('.erwachsene').val()
      data['kinder'] = $('.kinder').val()
      data['zusatztext'] = $('.zusatztext').val()
      data['preis'] = Preis
      data['anreise'] = getTimeString($('#anreise').datepick('getDate')[0])
      data['abreise'] = getTimeString($('#anreise').datepick('getDate')[1])

      data['services'] = ''
      $('input[type=checkbox]:checked').each(function (index, a) {
        data['services'] += a.name + ','
      })
      $('#servicesDiv select').each(function (index, a) {
        data['services'] += services[a.name].id + ','
      })
      data['servicesC'] = ''
      $('input[type=checkbox]:checked').each(function (index, a) {
        data['servicesC'] += 1 + ','
      })
      $('#servicesDiv select').each(function (index, a) {
        data['servicesC'] += a.value + ','
      })

      $.ajax({
        type: 'POST',
        url: domain + 'buchungen/',
        data: data,
        crossDomain: true,
        dataType: 'text',
        success: function (data) {
          alert(data)
        },
        error: function (data) {
          alert(
            data +
              'Irgendetwas ging schief! Sie koennen alternativ auch einfach eine E-Mail an buchung@ostseeurlaub-im-strandpark.de schicken oder anrufen.'
          )
        },
      })
    }
    sendContact = function (event) {
      data = {}
      data['title'] = $('.nameContact').val()
      data['from'] = $('.mailContact').val()
      data['body'] = $('.bodyContact').val()
      if (
        !data['from'] ||
        !data['from'].length > 8 ||
        !data['body'] ||
        !data['body'].length > 5
      ) {
        alert('Bitte geben Sie eine E-Mail Adresse sowie ihre Nachricht ein.')
        return
      }
      $.ajax({
        type: 'POST',
        url: domain + 'contact/',
        data: data,
        crossDomain: true,
        dataType: 'text',
        success: function (data) {
          alert(data)
        },
        error: function (data) {
          alert(
            data +
              'Irgendetwas ging schief! Sie koennen alternativ auch einfach eine E-Mail an buchung@ostseeurlaub-im-strandpark.de schicken oder anrufen.'
          )
        },
      })
      return false
    }

    postSendBuchungValidate = function () {
      if (!postSendBuchungTime) return
      ret = validate()
      ret = validateBuchung(ret)
      updateFieldState(ret)
      //Wir haben Warnungen/Fehler
      if (ret.length > 0) {
        buildInfoWidget(ret)
      }
    }
    preValidate = function () {
      ret = validate()
      if (postSendBuchungTime) ret = validateBuchung(ret)
      else {
        //services
        try {
          $('input[type=checkbox]:checked').each(function (index, a) {
            if (
              !getServicePreisInfoObjForDay(
                new Date($('#anreise').datepick('getDate')[0].getTime()),
                services[a.value]
              )
            ) {
              ret.push([
                'Service ' +
                  services[a.value].name +
                  ' ist in der gewaehlten Saison nicht verfuegbar',
                ['#anreise'],
              ])
              return
            }
            if (
              getServicePreisForDay(
                new Date($('#anreise').datepick('getDate')[0].getTime()),
                services[a.value]
              ) == 0
            ) {
              ret.push([
                'Service ' +
                  services[a.value].name +
                  ' ist in der gewaehlten Saison nicht verfuegbar',
                ['#anreise'],
              ])
            }
          })
          //services mit anzahl
          $('#servicesDiv select').each(function (index, a) {
            if (
              getServicePreisForDay(
                new Date($('#anreise').datepick('getDate')[0].getTime()),
                services[a.name]
              ) == 0 &&
              parseInt(a.value) > 0
            ) {
              ret.push([
                'Service ' +
                  services[a.name].name +
                  ' ist in der gewaehlten Saison nicht verfuegbar',
                ['#anreise'],
              ])
            }
          })
        } catch (e) {
          true
        }
      }
      if (ret.length > 0) {
        buildInfoWidget(ret)
      }
    }
    callBuchungen = function (a, state) {
      if (ie) {
        buchungen = JSON.parse(a)
      } else {
        buchungen = a
      }
    }
    callSaison = function (a, state) {
      if (ie) {
        saisons = JSON.parse(a)
      } else {
        saisons = a
      }
    }
    callService = function (a, state) {
      if (ie) {
        services = JSON.parse(a)
        a = JSON.parse(a)
      } else {
        services = a
      }

      htm = ''
      htmB = ''

      for (x in a) {
        disabled = ''
        htm += "<div class='col-sm-12 col-xs-12'>"
        htmB += "<div class='col-sm-12 col-xs-12'>"
        if (a[x].perPerson == false) {
          if (a[x].required) {
            disabled = 'disabled="disabled" checked="true"'
          }
          htmB +=
            '<div class="checkbox"><label class="col-xs-offset-1 col-xs-11 no-padding">'
          htmB +=
            '<input class="" type="checkbox" name="' +
            a[x].id +
            '" ' +
            disabled +
            '" onChange="preValidate()" value="' +
            x +
            '"> ' +
            a[x].name
          htmB += '</label></div>'
        } else {
          htm +=
            '<div class="select select-xs-width col-xs-2 col-sm-3 no-padding "><label>'
          htm +=
            '<select class="selectpicker span1"  onChange="preValidate()"  name="' +
            x +
            '" size="1">'

          i = 0
          if (a[x].required) {
            i++
          }

          for (i; i <= a[x].anzahlMax; i++) {
            htm += '<option>' + i + '</option>'
          }
          htm += '</select></label></div> '
          htm +=
            "<div class='col-xs-10 col-sm-9 no-padding service-list-margin'>" +
            a[x].name +
            '</div>'
        }
        htm += '</div>'
        htmB += '</div>'
      }

      $('#servicesDivA').html(htm)
      $('#servicesDivB').html(htmB)
      $('.selectpicker').selectpicker()
    }
    jQuery.support.cors = true // force cross-site scripting (as of jQuery 1.5)
    $.support.cors = true

    if (ie) {
      var callBu = $.getJSON(domain + 'buchungen/', '', callBuchungen)
      var callSai = $.getJSON(domain + 'saison/', '', callSaison)
      var callSe = $.getJSON(domain + 'services/', '', callService)
    } else {
      var callBu = $.getJSON(domain + 'buchungen/', callBuchungen)
      var callSai = $.getJSON(domain + 'saison/', callSaison)
      var callSe = $.getJSON(domain + 'services/', callService)
    }

    $.when(callBu, callSai).then(function () {
      //<div class="datepick-nav"><div class="col-xs-4">{link:prev}</div><div class="text-center col-xs-4">{link:today}</div><div class="text-right col-xs-4">{link:next}</div></div>
      var renderer = {
        picker:
          '<div class="datepick">' +
          '{months}' +
          '{popup:start}<div class="datepick-ctrl">{link:clear}{link:close}</div>{popup:end}' +
          '<div class="datepick-clear-fix"></div></div>',
        monthRow:
          '<div class="datepick-month-row col-xs-12  no-padding">{months}</div>',
        month:
          '<div class="datepick-month no-padding col-sm-6 col-xs-12 col-md-3"><div class="datepick-month-header col-xs-12">{monthHeader}</div>' +
          '<div class="col-xs-12 no-padding">{weekHeader}{weeks}</div></div>',
        weekHeader: '<div class="col-xs-12">{days}</div>',
        dayHeader: '<div class="dayDiv col-xs-1 no-padding">{day}</div>',
        week: '<div class="datepick-week col-xs-12">{days}</div>',
        day: '<div class="dayDiv no-padding col-xs-1 text-center">{day}</div>',
        monthSelector: '.datepick-month',
        daySelector: 'div',
        rtlClass: 'datepick-rtl',
        multiClass: 'datepick-multi',
        defaultClass: '',
        selectedClass: 'datepick-selected',
        highlightedClass: 'datepick-highlight',
        todayClass: 'datepick-today',
        otherMonthClass: 'datepick-other-month',
        weekendClass: 'datepick-weekend',
        commandClass: 'datepick-cmd',
        commandButtonClass: '',
        commandLinkClass: '',
        disabledClass: 'datepick-disabled',
      }

      if (ie <= 9) {
        setInterval(function () {
          if (saisons.length) {
            clearTimeout(this)
            $('#anreise').datepick(
              $.extend(
                {
                  monthsToShow: 4,
                  prevText: 'zurück',
                  nextText: 'weiter',
                  renderer: renderer,
                  defaultDate: '',
                  selectDefaultDate: true,
                  minDate: 0,
                  maxDate: getIDate(saisons[saisons.length - 1].anfang),
                  changeMonth: true,
                  rangeSelect: true,
                  onDate: renderDay,
                  //onShow:renderDay,
                  onSelect: preValidate,
                },
                $.datepick.regionalOptions['de']
              )
            )
          }
        }, 100)
      } else {
        $('#anreise').datepick(
          $.extend(
            {
              monthsToShow: 4,
              prevText: 'zurück',
              nextText: 'weiter',
              renderer: renderer,
              defaultDate: '',
              selectDefaultDate: true,
              minDate: 0,
              maxDate: getIDate(saisons[saisons.length - 1].anfang),
              changeMonth: true,
              rangeSelect: true,
              onDate: renderDay,
              //onShow:renderDay,
              onSelect: preValidate,
            },
            $.datepick.regionalOptions['de']
          )
        )
      }

      //$('#anreise').datepick($.datepick.regional['de']);
      //$('#overview').datepick($.datepick.regional['de']);
      for (field in fields) {
        $(fields[field]).on('input', null, null, postSendBuchungValidate)
      }
      for (field in fields) {
        $(fields[field] + '-inactive').on(
          'input',
          null,
          null,
          postSendBuchungValidate
        )
      }
    })
  })
})
